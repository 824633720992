import React from 'react';
import './Fish.css'

const Fish = () => {
  return (
    <div className='container'>
      <h1>Fish!</h1>
      <p>Welcome.... Fishing coming soon.....$kwak</p>
    </div>
  );
};

export default Fish;
